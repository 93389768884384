import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsCondition from "./components/TermsCondition";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms-condition" element={<TermsCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
