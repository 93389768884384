import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence, useInView } from "framer-motion";
import { ArrowRight, ChevronRight, Plus, Trash, Minus } from "lucide-react";

// Add these new functions and component
const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Math.round(value));
};

const Slider = ({ value, onChange, min, max, step }) => {
  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
    />
  );
};

const sectors = [
  "Private Equity",
  "Family Office",
  "Venture Capital",
  "Investment Banking",
  // "M&A Advisory",
  "Private Credit",
  "Real Estate", // Changed from "REPE" to "Real Estate"
];

const painPoints = {
  "Private Equity": [
    {
      id: 1,
      title: "Overstated EBITDA Add-Backs",
      description:
        "Forensically audit EBITDA adjustments, including one-time costs that are overstated or misclassified.",
      deliverable:
        "A detailed EBITDA adjustment breakdown with suggested corrections for the IC memo.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 2,
      title: "Revenue Reliance on Few Customers",
      description:
        "Surface risks where a large percentage of revenue is concentrated in one or two customers, especially if they are on short-term contracts.",
      deliverable:
        "Customer risk analysis report highlighting termination risks and potential churn impact on revenue.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 3,
      title: "Unclear Competitive Advantage",
      description:
        "Struggle to identify and quantify the target company's true competitive edge in the market, risking overvaluation or missed opportunities.",
      deliverable:
        "A detailed competitive advantage analysis report, highlighting unique selling propositions and sustainable market positioning for the IC memo.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 4,
      title: "Hidden Liabilities in Legal Contracts",
      description:
        "Automatically extract reps & warranties, indemnities, and potential liabilities from legal agreements.",
      deliverable:
        "A contractual risk report with all hidden liability exposures surfaced for the IC.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 5,
      title: "Unsustainable Earnings Trends",
      description:
        "Cross-check earnings quality to ensure that revenue and profit trends are sustainable.",
      deliverable:
        "QoE report that highlights any issues with revenue recognition or unsustainable one-time income.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 6,
      title: "Covenant Breach Exposure",
      description:
        "Analyze the company's existing debt structure, ensuring there are no covenant breaches that could impact future operations.",
      deliverable:
        "A covenant compliance report that outlines existing debt terms and future breach risks.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 7,
      title: "Operational Inefficiencies",
      description:
        "Benchmark operational performance against competitors to identify inefficiencies.",
      deliverable:
        "An efficiency gap analysis that highlights areas for operational improvements post-acquisition.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 8,
      title: "Restrictive Employee Contract Liabilities",
      description:
        "Analyze employee contracts for any restrictive covenants or long-term liabilities.",
      deliverable:
        "Employment contract risk analysis showing future compensation obligations and retention risks.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 9,
      title: "Missed Synergy Opportunities",
      description:
        "Identify key areas where operational or cost synergies can be realized post-acquisition.",
      deliverable:
        "A synergy forecast detailing cost savings or revenue enhancements across functions.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 10,
      title: "Unrealistic Exit Multiples",
      description:
        "Analyze potential exit multiples under different market scenarios to gauge upside potential.",
      deliverable:
        "A multiple sensitivity report showing return scenarios based on varying exit multiples and market conditions.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
  ],
  "Family Office": [
    {
      id: 11,
      title: "Cross-Border Tax Inefficiencies",
      description:
        "Evaluate tax implications of multi-jurisdictional investments, especially across inheritance tax and estate tax.",
      deliverable:
        "A tax structuring memo outlining the optimal investment and inheritance structure.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 12,
      title: "Illiquid Asset Constraints",
      description:
        "Analyze liquidity profiles of illiquid assets like real estate and private equity holdings.",
      deliverable:
        "Liquidity risk report detailing timeframes and scenarios for cash flow availability in times of need.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 13,
      title: "Governance Conflicts",
      description:
        "Identify governance risks that could arise from family disagreements or power shifts.",
      deliverable:
        "Governance risk memo with recommended structures to mitigate internal family conflicts.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 14,
      title: "Overvalued Real Estate Holdings",
      description:
        "Cross-check appraisals across a multi-property portfolio, benchmarking values against market data.",
      deliverable:
        "A real estate portfolio value comparison that highlights any overvalued or undervalued properties.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 15,
      title: "Risky Alternative Investments",
      description:
        "Evaluate art, collectibles, and other alternative investments for long-term growth and capital preservation.",
      deliverable:
        "Alternative asset investment review summarizing expected appreciation rates and risks.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 16,
      title: "Unpredictable Cash Flows",
      description:
        "Assess cash flow predictability from investments, especially real estate or private equity portfolios.",
      deliverable:
        "A cash flow forecast for all key assets under various economic scenarios.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 17,
      title: "Succession Planning Vulnerabilities",
      description:
        "Flag risks in succession plans, especially where governance documents may lead to disputes.",
      deliverable:
        "Succession plan risk assessment, identifying weaknesses in current governance structures.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 18,
      title: "Underperforming Fund Managers",
      description:
        "Conduct due diligence on external fund managers for private equity or hedge fund allocations.",
      deliverable:
        "A fund manager performance review outlining track record, risks, and alignment with family goals.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 19,
      title: "Unbalanced Portfolio Performance",
      description:
        "Benchmark the performance of real estate, private equity, and public market investments together.",
      deliverable:
        "A comprehensive portfolio performance report tracking returns across asset classes.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 20,
      title: "Estate and Investment Misalignment",
      description:
        "Ensure estate planning aligns with current investments and future liquidity events.",
      deliverable:
        "A comprehensive estate alignment plan to sync future cash needs with investment timelines.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
  ],
  "Venture Capital": [
    {
      id: 21,
      title: "Founder's Inexperience in Market",
      description:
        "Evaluate founder experience and alignment with market dynamics, avoiding overconfidence and risky ventures.",
      deliverable:
        "A founder risk report with insights into their past execution and market fit.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 22,
      title: "Excessive Cap Table Dilution",
      description:
        "Analyze cap tables for potential dilution issues, especially in future rounds.",
      deliverable:
        "A cap table risk memo showing dilution scenarios for current and future investors.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 23,
      title: "False Product-Market Fit Claims",
      description:
        "Verify true product-market fit by analyzing customer data and market traction beyond deck claims.",
      deliverable:
        "Market traction report confirming the authenticity of customer growth metrics.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 24,
      title: "Unscalable Unit Economics",
      description:
        "Analyze CAC/LTV ratios, burn rate, and unit economics to verify scalability.",
      deliverable:
        "A unit economics deep dive showing true customer acquisition costs and long-term value.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 25,
      title: "Operational Bottlenecks for Scaling",
      description:
        "Identify scalability risks in operational structure or product, ensuring growth projections are realistic.",
      deliverable:
        "Scalability assessment report with risks and recommendations for scaling.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 26,
      title: "Hidden Churn in Customer Retention",
      description:
        "Dive into customer data to identify real churn rates vs vanity metrics on retention.",
      deliverable:
        "A churn analysis memo that highlights customer stickiness and the sustainability of ARR growth.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 27,
      title: "Competitive Landscape Blind Spots",
      description:
        "Map out competitive threats based on market intelligence and position the startup's differentiation.",
      deliverable:
        "A competitor analysis report showing gaps and threats in the competitive landscape.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 28,
      title: "Mismatched Funding Milestones",
      description:
        "Ensure funding rounds are correctly timed to product and operational milestones.",
      deliverable:
        "A milestone roadmap aligning capital needs with execution goals.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 29,
      title: "Technical Debt and Scalability Issues",
      description:
        "Analyze the startup's tech stack for technical debt and scalability limitations.",
      deliverable:
        "Tech stack audit report identifying any infrastructure risks.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 30,
      title: "Exit Scenarios Mismatch",
      description:
        "Forecast potential exit scenarios and align them with market trends and valuation multiples.",
      deliverable:
        "Exit scenario analysis highlighting possible acquisition paths or IPO windows.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
  ],
  "Private Credit": [
    {
      id: 31,
      title: "Weak Debt Covenants",
      description:
        "Analyze the strength of debt covenants and likelihood of breaches.",
      deliverable:
        "A covenant compliance report forecasting breach risks over time.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 32,
      title: "DSCR Underperformance",
      description:
        "Evaluate DSCR under different stress scenarios to predict borrower health.",
      deliverable:
        "A DSCR forecast memo showing how cash flows will cover debt service under varying economic conditions.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 33,
      title: "Overstated Collateral Valuation",
      description:
        "Ensure collateral is accurately valued relative to loan size and market conditions.",
      deliverable:
        "A collateral adequacy report ensuring assets cover loan exposure adequately.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 34,
      title: "Borrower Financial Instability",
      description:
        "Analyze borrower financials for any signs of weakness, including earnings quality and cash flow volatility.",
      deliverable:
        "A borrower risk report showing liquidity, earnings strength, and financial stability.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 35,
      title: "Risky Loan Agreement Terms",
      description:
        "Highlight risky terms in loan agreements that could impact future performance or compliance.",
      deliverable:
        "A loan agreement risk memo identifying contract weaknesses or unfavorable terms.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 36,
      title: "Debt Restructuring Pitfalls",
      description:
        "Analyze debt structure for refinancing risks or hidden costs in covenants.",
      deliverable:
        "A debt structuring assessment identifying refinancing opportunities and potential pitfalls.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 37,
      title: "High Default Probability",
      description:
        "Forecast default probabilities based on borrower behavior and financial trends.",
      deliverable:
        "A default probability report showing default risks under various stress tests.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 38,
      title: "Upcoming Refinancing Stress",
      description:
        "Evaluate refinancing risk for loans with upcoming maturities or volatile borrowers.",
      deliverable:
        "A refinancing risk memo with actionable insights on approaching maturities.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 39,
      title: "Cross-Collateralization Vulnerabilities",
      description:
        "Identify any cross-collateralization risks in multiple loan agreements.",
      deliverable:
        "A collateral risk analysis identifying interdependencies between loans.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 40,
      title: "Liquidity Shortfalls",
      description:
        "Ensure borrower liquidity aligns with upcoming debt service needs, flagging any gaps.",
      deliverable:
        "A liquidity gap forecast highlighting periods where cash flow may fall short of debt service.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
  ],
  "Real Estate": [
    {
      id: 41,
      title: "Tenant Credit Exposure",
      description:
        "Analyze the credit quality of major tenants, especially in multi-tenant properties.",
      deliverable:
        "A tenant risk memo highlighting tenant vulnerabilities or upcoming lease expirations.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 42,
      title: "Overestimated Rental Income",
      description:
        "Verify rental income projections against historical data and market trends.",
      deliverable:
        "An income stability report with detailed cash flow analysis.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 43,
      title: "Discrepancies in Cap Rate Valuations",
      description:
        "Ensure cap rates are aligned with market comparables, flagging any discrepancies.",
      deliverable:
        "A cap rate analysis showing alignment or discrepancies vs market trends.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 44,
      title: "Inflated Appraisal Values",
      description:
        "Validate third-party appraisals against Wellstone's own analysis of comparable sales and market conditions.",
      deliverable:
        "A cross-checked appraisal report showing variances from market comps.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 45,
      title: "Weak Lease Terms",
      description:
        "Analyze lease terms, especially for long-term viability and escalations.",
      deliverable:
        "A lease strength analysis highlighting risks like short terms or poor escalation clauses.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 46,
      title: "Underestimated CapEx Needs",
      description:
        "Analyze future capital expenditure needs and maintenance schedules.",
      deliverable:
        "A capex forecast memo ensuring maintenance costs are in line with rental income projections.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 47,
      title: "Market Volatility Risks",
      description:
        "Forecast how changes in market conditions (e.g., rising interest rates) might impact property values and income.",
      deliverable:
        "A market volatility report showing how macroeconomic trends will impact asset value.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 48,
      title: "Over-Reliance on Key Tenants",
      description:
        "Flag risks from over-reliance on a small number of tenants for revenue.",
      deliverable:
        "A tenant concentration risk analysis outlining exposure to single-tenant risks.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 49,
      title: "Future Vacancy Concerns",
      description:
        "Forecast vacancy rates based on local market conditions and tenant retention risk.",
      deliverable:
        "A vacancy forecast showing expected occupancy trends over time.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 50,
      title: "Unstable Rent Roll",
      description:
        "Analyze rent roll data for stability, including upcoming lease expirations or rent escalations.",
      deliverable:
        "A rent roll stability report highlighting any risks to income continuity.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
  ],
  "Investment Banking": [
    {
      id: 51,
      title: "CIM Preparation Bottlenecks",
      description:
        "Automate the drafting of CIMs based on uploaded financials and operational metrics, reducing manual work.",
      deliverable:
        "An automated CIM ready for client review, with all relevant sections filled out.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 52,
      title: "Weak Growth Narrative",
      description:
        "Craft a growth narrative based on company performance and industry trends.",
      deliverable:
        "A growth narrative report aligning with the company's long-term vision.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 53,
      title: "Repetitive Buyer Q&A",
      description:
        "Manage buyer due diligence queries by automatically answering repetitive questions.",
      deliverable:
        "A buyer Q&A dashboard that centralizes and automates responses to common queries.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 54,
      title: "Inaccurate Financial Models",
      description:
        "Cross-check financial models to ensure accuracy and alignment with the company's historical performance.",
      deliverable:
        "A financial model validation report flagging any errors or misalignments.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 55,
      title: "Operational Inefficiencies",
      description:
        "Analyze the company's operational metrics for efficiency and scalability.",
      deliverable:
        "An operational performance report highlighting strengths and weaknesses.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 56,
      title: "Poor Industry Positioning",
      description:
        "Benchmark the company's performance against industry peers to position it better in the market.",
      deliverable:
        "An industry benchmark analysis positioning the company against key competitors.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 57,
      title: "Undisclosed Legal Liabilities",
      description:
        "Review legal documents to ensure there are no hidden risks or liabilities for buyers.",
      deliverable:
        "A legal risk memo identifying potential deal risks from contracts and other legal agreements.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 58,
      title: "Misaligned Buyer Personas",
      description:
        "Develop buyer personas based on deal history, identifying the most likely buyers for the company.",
      deliverable:
        "A buyer persona report that helps target strategic buyers more effectively.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 59,
      title: "Unjustified EBITDA Adjustments",
      description:
        "Justify EBITDA adjustments to make the company more attractive to buyers, including non-recurring expenses.",
      deliverable:
        "An EBITDA adjustment memo explaining one-time costs or add-backs to boost valuation.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
    {
      id: 60,
      title: "Missed Negotiation Leverage",
      description:
        "Identify negotiation points that can help secure better deal terms for the seller.",
      deliverable:
        "A negotiation point analysis showing key areas where the seller can push for better terms.",
      videoUrl: "https://player.vimeo.com/video/1012996733",
    },
  ],
};

const HEADER_HEIGHT = 64; // Adjust this value to match your header's actual height

export default function Home() {
  const [selectedSector, setSelectedSector] = useState("Private Equity");
  const [selectedPainPoint, setSelectedPainPoint] = useState(
    painPoints["Private Equity"][0].id
  );
  const [step, setStep] = useState(1);
  const [staffMembers, setStaffMembers] = useState([
    { id: 1, title: "Associate", hoursSpent: 5, hourlyRate: 150, count: 1 },
    {
      id: 2,
      title: "Principal",
      hoursSpent: 2,
      hourlyRate: 300,
      count: 1,
    },
  ]);

  const [roiInputs, setRoiInputs] = useState({
    dealsClosedPerYear: 2, // Changed from annualDealVolume
    averageLegalCostPerDeal: 20000,
    estimatedLegalCostReduction: 10, // Default 10% reduction
  });

  const roiRef = useRef(null);
  const isInView = useInView(roiRef, { once: true, amount: 0.2 });

  const addStaffMember = () => {
    const newId = Date.now(); // Use timestamp for unique ID
    setStaffMembers([
      ...staffMembers,
      {
        id: newId,
        title: "New Staff",
        hoursSpent: 5,
        hourlyRate: 150,
        count: 1,
      },
    ]);
  };

  const removeStaffMember = (id) => {
    setStaffMembers(staffMembers.filter((member) => member.id !== id));
  };

  const updateStaffMember = (id, field, value) => {
    setStaffMembers(
      staffMembers.map((member) =>
        member.id === id
          ? {
              ...member,
              [field]: field === "title" ? value : Number(value),
            }
          : member
      )
    );
  };

  const adjustStaffCount = (id, increment) => {
    setStaffMembers(
      staffMembers.map((member) =>
        member.id === id
          ? { ...member, count: Math.max(1, member.count + increment) }
          : member
      )
    );
  };

  const updateRoiInput = (field, value) => {
    setRoiInputs((prev) => ({ ...prev, [field]: Number(value) }));
  };

  const calculateDetailedROI = () => {
    // Time savings calculation (monthly)
    const totalHoursSaved =
      staffMembers.reduce(
        (acc, member) => acc + member.hoursSpent * member.count,
        0
      ) * 4;
    const totalCostSaved =
      staffMembers.reduce(
        (acc, member) =>
          acc + member.hoursSpent * member.hourlyRate * member.count,
        0
      ) * 4;

    // Legal cost savings (monthly)
    const annualLegalCosts =
      roiInputs.dealsClosedPerYear * roiInputs.averageLegalCostPerDeal;
    const annualLegalCostSavings =
      annualLegalCosts * (roiInputs.estimatedLegalCostReduction / 100);
    const monthlyLegalCostSavings = annualLegalCostSavings / 12;

    const totalMonthlySavings = totalCostSaved + monthlyLegalCostSavings;

    const planCost = 2500; // $2,500 per month

    const netMonthlySavings = totalMonthlySavings - planCost;

    const calculateROI = () => {
      return (netMonthlySavings / planCost) * 100;
    };

    return {
      totalHoursSaved,
      totalCostSaved,
      legalCostSavings: monthlyLegalCostSavings,
      totalMonthlySavings,
      planCost,
      netMonthlySavings,
      roi: calculateROI(),
    };
  };

  useEffect(() => {
    setSelectedPainPoint(painPoints[selectedSector][0].id);
  }, [selectedSector]);

  useEffect(() => {
    document.body.style.paddingTop = "0";
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -HEADER_HEIGHT; // Adjust for fixed header
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const links = [
    { label: "Privacy", href: "/privacy-policy" },
    { label: "Terms", href: "/terms-condition" },
    { label: "Contact", href: "mailto:support@wellstone.ai" },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-900">
      {/* Header */}
      <header className="fixed w-full z-50 bg-white/80 backdrop-blur-md border-b border-gray-200">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <span className="text-xl font-semibold">Wellstone</span>
            </div>
            <nav className="hidden md:flex space-x-8">
              {[
                { name: "Pain Points", id: "pain-points" },
                { name: "ROI Calculator", id: "roi-calculator" },
                { name: "Deliverables", id: "features" }, // Changed from "How It Works" to "Deliverables"
              ].map((item) => (
                <button
                  key={item.name}
                  onClick={() => scrollToSection(item.id)}
                  className="text-sm font-medium text-gray-500 hover:text-gray-900 transition-colors cursor-pointer"
                >
                  {item.name}
                </button>
              ))}
            </nav>
            <div className="flex items-center space-x-4">
              <a
                href={process.env.REACT_APP_LOGIN_URL}
                className="text-sm cursor-pointer font-medium text-gray-500 hover:text-gray-900 transition-colors"
              >
                Login
              </a>
              <a
                href="https://cal.com/wellstone/15min?date=2024-11-02&month=2024-11"
                className="text-sm font-medium bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
              >
                Book a demo
              </a>
            </div>
          </div>
        </div>
      </header>

      <main className="flex-grow">
        {/* Hero Section */}
        <section className="pt-16 pb-16 sm:pt-24 sm:pb-24 lg:pt-32 lg:pb-32">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
              className="text-center max-w-3xl mx-auto"
            >
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl"
              >
                Evaluate deals & draft exhibits
                <motion.span
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                  className="text-blue-600"
                >
                  {" "}
                  10x faster
                </motion.span>
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.6 }}
                className="mt-6 text-xl text-gray-500"
              >
                10x the productivity of your deal team. No additional team
                members necessary.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.8 }}
                className="mt-10 flex justify-center"
              >
                <motion.a
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  href="https://cal.com/wellstone/15min?date=2024-11-02&month=2024-11"
                  className="px-6 py-3 text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors"
                >
                  Book a demo
                </motion.a>
              </motion.div>
            </motion.div>
          </div>
        </section>

        {/* Pain Points Section */}
        <section
          id="pain-points"
          className="py-16 bg-gray-50 sm:py-20 lg:py-24"
        >
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-center mb-4">
                Top 10 Deal Evaluation Pain Points Solved
              </h2>
              <p className="text-lg text-gray-500 text-center mb-8">
                Based on a survey of investment professionals across LMM, family
                offices, investment banks, and transaction advisory firms
              </p>
              <div className="mb-8 flex flex-wrap justify-center gap-2">
                {sectors.map((sector) => (
                  <button
                    key={sector}
                    onClick={() => setSelectedSector(sector)}
                    className={`px-4 py-2 rounded-full text-sm font-medium ${
                      selectedSector === sector
                        ? "bg-blue-600 text-white"
                        : "bg-white text-gray-700 hover:bg-gray-100"
                    }`}
                  >
                    {sector}
                  </button>
                ))}
              </div>
              <div className="flex flex-col lg:flex-row gap-8">
                <div className="w-full lg:w-1/3 space-y-2">
                  {painPoints[selectedSector].map((point, index) => (
                    <motion.div
                      key={point.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      className={`bg-white rounded-lg shadow-sm overflow-hidden cursor-pointer transition-all ${
                        selectedPainPoint === point.id
                          ? "ring-2 ring-blue-600"
                          : ""
                      }`}
                      onClick={() => setSelectedPainPoint(point.id)}
                    >
                      <div className="p-3 flex items-center justify-between">
                        <div>
                          <h3 className="text-sm font-semibold">
                            {point.title}
                          </h3>
                          <p className="text-xs text-gray-500 mt-1">
                            {selectedSector}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <span className="text-xs font-medium text-green-600 bg-green-100 px-2 py-1 rounded-full mr-2">
                            #{index + 1}
                          </span>
                          <ChevronRight
                            className={`h-4 w-4 text-blue-600 transition-transform ${
                              selectedPainPoint === point.id
                                ? "transform rotate-90"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <div className="w-full lg:w-2/3">
                  <AnimatePresence mode="wait">
                    {painPoints[selectedSector].map(
                      (point) =>
                        selectedPainPoint === point.id && (
                          <motion.div
                            key={point.id}
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -20 }}
                            transition={{ duration: 0.3 }}
                            className="bg-white rounded-lg shadow-lg overflow-hidden"
                          >
                            <div className="p-6">
                              <h3 className="text-xl font-semibold mb-2">
                                {point.title}
                              </h3>
                              <p className="text-gray-600 mb-4">
                                {point.description}
                              </p>
                              <div className="bg-blue-50 rounded-md p-4 mb-4">
                                <p className="text-sm text-blue-700">
                                  <strong>Deliverable:</strong>{" "}
                                  {point.deliverable}
                                </p>
                              </div>
                              <div className="relative aspect-video bg-gray-200 rounded-md overflow-hidden">
                                <iframe
                                  src={point.videoUrl}
                                  width="100%"
                                  height="100%"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            </div>
                          </motion.div>
                        )
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Updated ROI Calculator Section */}
        <section
          id="roi-calculator"
          ref={roiRef}
          className="py-16 bg-gradient-to-br from-blue-50 to-indigo-100 sm:py-20 lg:py-24"
        >
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              className="text-3xl font-bold text-blue-900 text-center mb-8"
            >
              Calculate Your ROI with Wellstone
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="max-w-7xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden"
            >
              <div className="p-8 sm:p-12">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                  {/* Left column: ROI Summary */}
                  <div className="lg:col-span-1">
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={
                        isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }
                      }
                      transition={{ duration: 0.6, delay: 0.3 }}
                      className="bg-gradient-to-br from-blue-500 to-indigo-600 rounded-xl p-6 text-white shadow-lg"
                    >
                      <h3 className="text-2xl font-bold mb-6">
                        Your Monthly ROI Summary
                      </h3>
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <span className="text-blue-100">
                            Total Hours Saved
                          </span>
                          <span className="text-xl font-bold">
                            {Math.round(calculateDetailedROI().totalHoursSaved)}{" "}
                            hours
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-blue-100">
                            Time Cost Savings
                          </span>
                          <span className="text-xl font-bold">
                            {formatCurrency(
                              Math.round(calculateDetailedROI().totalCostSaved)
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-blue-100">
                            Legal Cost Savings
                          </span>
                          <span className="text-xl font-bold">
                            {formatCurrency(
                              Math.round(
                                calculateDetailedROI().legalCostSavings
                              )
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-blue-100">
                            Wellstone Monthly Cost
                          </span>
                          <span className="text-xl font-bold">
                            {formatCurrency(calculateDetailedROI().planCost)}
                          </span>
                        </div>
                        <div className="pt-4 mt-4 border-t border-blue-400">
                          <div className="flex justify-between items-center">
                            <span className="text-lg font-semibold text-green-300">
                              Net Monthly Savings
                            </span>
                            <span className="text-2xl font-bold text-green-300">
                              {formatCurrency(
                                Math.round(
                                  calculateDetailedROI().netMonthlySavings
                                )
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-lg font-semibold text-green-300">
                            Net Annual Savings
                          </span>
                          <span className="text-2xl font-bold text-green-300">
                            {formatCurrency(
                              Math.round(
                                calculateDetailedROI().netMonthlySavings * 12
                              )
                            )}
                          </span>
                        </div>
                      </div>
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={
                          isInView
                            ? { opacity: 1, y: 0 }
                            : { opacity: 0, y: 20 }
                        }
                        transition={{ duration: 0.6, delay: 0.5 }}
                        className="mt-6"
                      >
                        <h4 className="text-xl font-semibold mb-2">
                          Monthly ROI
                        </h4>
                        <div className="bg-white bg-opacity-10 rounded-lg p-3">
                          <span className="text-blue-100">
                            Wellstone Plan ROI
                          </span>
                          <span className="text-2xl font-bold float-right">
                            {Math.round(calculateDetailedROI().roi)}%
                          </span>
                        </div>
                      </motion.div>
                    </motion.div>
                  </div>

                  {/* Middle column: Staff Information */}
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={
                      isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                    }
                    transition={{ duration: 0.6, delay: 0.4 }}
                    className="lg:col-span-1"
                  >
                    <h3 className="text-xl font-semibold mb-4 text-gray-800">
                      Staff Information
                    </h3>
                    <div className="space-y-4 max-h-[400px] overflow-y-auto pr-4">
                      {staffMembers.map((member, index) => (
                        <motion.div
                          key={member.id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={
                            isInView
                              ? { opacity: 1, y: 0 }
                              : { opacity: 0, y: 20 }
                          }
                          transition={{ duration: 0.4, delay: 0.1 * index }}
                          className="p-4 bg-gray-50 rounded-lg shadow-sm"
                        >
                          <div className="flex justify-between items-center mb-3">
                            <input
                              type="text"
                              value={member.title}
                              onChange={(e) =>
                                updateStaffMember(
                                  member.id,
                                  "title",
                                  e.target.value
                                )
                              }
                              className="text-sm font-medium bg-transparent border-b border-blue-300 focus:border-blue-500 focus:outline-none"
                            />
                            <div className="flex items-center space-x-2">
                              <button
                                onClick={() => adjustStaffCount(member.id, -1)}
                                className="text-blue-500 hover:text-blue-700"
                              >
                                <Minus size={14} />
                              </button>
                              <span className="text-sm font-medium">
                                {member.count}
                              </span>
                              <button
                                onClick={() => adjustStaffCount(member.id, 1)}
                                className="text-blue-500 hover:text-blue-700"
                              >
                                <Plus size={14} />
                              </button>
                              {staffMembers.length > 1 && (
                                <button
                                  onClick={() => removeStaffMember(member.id)}
                                  className="text-red-500 hover:text-red-700 ml-2"
                                >
                                  <Trash size={14} />
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="space-y-3">
                            <div>
                              <label className="block text-xs font-medium text-gray-700 mb-1">
                                Weekly hours on doc review & analysis
                              </label>
                              <div className="flex items-center">
                                <Slider
                                  value={member.hoursSpent}
                                  onChange={(value) =>
                                    updateStaffMember(
                                      member.id,
                                      "hoursSpent",
                                      value
                                    )
                                  }
                                  min={0}
                                  max={40}
                                  step={1}
                                  className="flex-grow"
                                />
                                <span className="text-xs text-gray-500 ml-2 w-12 text-right">
                                  {member.hoursSpent}h
                                </span>
                              </div>
                            </div>
                            <div>
                              <label className="block text-xs font-medium text-gray-700 mb-1">
                                Hourly rate
                              </label>
                              <div className="flex items-center">
                                <Slider
                                  value={member.hourlyRate}
                                  onChange={(value) =>
                                    updateStaffMember(
                                      member.id,
                                      "hourlyRate",
                                      value
                                    )
                                  }
                                  min={50}
                                  max={500}
                                  step={10}
                                  className="flex-grow"
                                />
                                <span className="text-xs text-gray-500 ml-2 w-16 text-right">
                                  ${member.hourlyRate}/h
                                </span>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                    <motion.button
                      initial={{ opacity: 0, y: 20 }}
                      animate={
                        isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                      transition={{ duration: 0.4, delay: 0.6 }}
                      onClick={addStaffMember}
                      className="mt-4 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                    >
                      <Plus size={16} className="inline mr-2" /> Add Staff
                      Member
                    </motion.button>
                  </motion.div>

                  {/* Right column: Deal Information */}
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={
                      isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }
                    }
                    transition={{ duration: 0.6, delay: 0.5 }}
                    className="lg:col-span-1"
                  >
                    <h3 className="text-xl font-semibold mb-4 text-gray-800">
                      Deal Information
                    </h3>
                    <div className="space-y-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Deals Closed Per Year
                        </label>
                        <Slider
                          value={roiInputs.dealsClosedPerYear}
                          onChange={(newValue) =>
                            updateRoiInput("dealsClosedPerYear", newValue)
                          }
                          min={1}
                          max={20}
                          step={1}
                        />
                        <span className="text-sm text-gray-500">
                          {roiInputs.dealsClosedPerYear} deals/year
                        </span>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Average Legal Cost per Deal
                        </label>
                        <Slider
                          value={roiInputs.averageLegalCostPerDeal}
                          onChange={(newValue) =>
                            updateRoiInput("averageLegalCostPerDeal", newValue)
                          }
                          min={5000}
                          max={100000}
                          step={1000}
                        />
                        <span className="text-sm text-gray-500">
                          {formatCurrency(roiInputs.averageLegalCostPerDeal)}
                          /deal
                        </span>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Estimated Legal Cost Reduction
                        </label>
                        <Slider
                          value={roiInputs.estimatedLegalCostReduction}
                          onChange={(newValue) =>
                            updateRoiInput(
                              "estimatedLegalCostReduction",
                              newValue
                            )
                          }
                          min={0}
                          max={30}
                          step={1}
                        />
                        <span className="text-sm text-gray-500">
                          {roiInputs.estimatedLegalCostReduction}% reduction
                        </span>
                        <div className="mt-2 text-xs text-gray-500">
                          Our default assumption is a 10% reduction in legal
                          costs based on customer data.
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Transform Your Deal Process Section */}
        <section id="features" className="py-16 sm:py-20 lg:py-24">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.6, delay: 0.1 }}
              >
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                  Transform Your Deal Process
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Wellstone revolutionizes your workflow from day one, starting
                  with key deliverables in mind.
                </p>
                <ul className="mt-8 space-y-4">
                  {[
                    "50%+ of IC memo generated with one click",
                    "IM becomes the first deliverable, not the last",
                    "Automated data analysis from day one of VDR access",
                  ].map((item, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true, amount: 0.2 }}
                      transition={{ duration: 0.4, delay: 0.2 + index * 0.1 }}
                      className="flex items-start"
                    >
                      <ArrowRight className="h-6 w-6 text-blue-600 flex-shrink-0" />
                      <span className="ml-3 text-gray-500">{item}</span>
                    </motion.li>
                  ))}
                </ul>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, amount: 0.2 }}
                  transition={{ duration: 0.6, delay: 0.5 }}
                  className="mt-10 flex gap-4"
                >
                  <a
                    href="https://cal.com/wellstone/15min?date=2024-11-02&month=2024-11"
                    className="px-6 py-3 text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors"
                  >
                    Book a demo
                  </a>
                  {/* <a
                    href="#"
                    className="px-6 py-3 text-base font-medium rounded-md text-blue-600 bg-blue-50 hover:bg-blue-100 transition-colors"
                  >
                    Schedule demo
                  </a> */}
                </motion.div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.6, delay: 0.3 }}
                className="mt-10 lg:mt-0"
              >
                <video
                  src="/LatestVid4kOfficial.mov"
                  className="rounded-lg shadow-lg w-full h-auto"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  Your browser does not support the video tag.
                </video>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section id="cta" className="bg-blue-700 py-16 sm:py-20 lg:py-24">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Ready to revolutionize your deal process?
              </h2>
              <p className="mt-4 text-xl text-blue-100">
                Join successful firms leveraging Wellstone to close deals faster
                and more efficiently.
              </p>
              <a
                href="https://cal.com/wellstone/15min?date=2024-11-02&month=2024-11"
                className="mt-8 inline-block px-6 py-3 text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50 transition-colors"
              >
                Book a demo
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-50 border-t border-gray-200">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
            <h2 className="text-xl font-bold text-gray-900 mb-6">WELLSTONE</h2>
            <div className="flex justify-center space-x-8">
              {links.map((item) => (
                <a
                  key={item.label}
                  href={item.href}
                  className="text-base text-gray-500 hover:text-gray-900 transition-colors"
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
          <div className="mt-8 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-400 text-center">
              © Copyright 2024 Wellstone AI, LLC. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
